.cab-top-message {
  display: none;
}

.sidebar {
  display: none;
}

.notyf {
  display: none;
}